import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const NotFoundPage = ({ data, location }) => (
  <Layout seoTitle="404" seoDescription="404" location={location}>
    <Fade ssrReveal delay={250} duration={2250}>
      <img
        src={data.file.childImageSharp.large.src}
        className="hidden md:block absolute w-full h-screen z-10 opacity-50 object-cover"
        alt="Page not found"
      />
    </Fade>
    <div className="relative h-full flex items-center pt-32 z-20">
      <Fade ssrReveal delay={450} duration={2250} bottom distance={'25px'}>
        <div className="wrapper px-ogs text-center">
          <h1 className="text-2xl xl:text-3xl font-condensed uppercase mb-4">
            404
          </h1>
          <p className="mb-8 md:text-lg">
            Sorry, but the page you were looking for could not be found.
          </p>
          <AniLink fade to={'/'} className="button">
            Home
          </AniLink>
        </div>
      </Fade>
    </div>
  </Layout>
)

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFoundPage

export const query = graphql`
  query {
    file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        large: resize(width: 1200, quality: 85) {
          src
        }
      }
    }
  }
`
